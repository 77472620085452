var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-snackbar",
    {
      attrs: { timeout: _vm.timeout, color: _vm.color },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function ({ attrs }) {
            return [
              _c(
                "v-btn",
                _vm._b(
                  {
                    attrs: { text: "" },
                    on: {
                      click: function ($event) {
                        _vm.show = false
                      },
                    },
                  },
                  "v-btn",
                  attrs,
                  false
                ),
                [_vm._v(" Close ")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [_vm._v(" " + _vm._s(_vm.message) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }