var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600px" },
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              return _vm.close.apply(null, arguments)
            },
          },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "primary white--text" }, [
                _c("span", { staticClass: "text-h4" }, [
                  _vm._v("Roles and Permissions"),
                ]),
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-tabs",
                        {
                          model: {
                            value: _vm.tab,
                            callback: function ($$v) {
                              _vm.tab = $$v
                            },
                            expression: "tab",
                          },
                        },
                        [
                          _c(
                            "v-tab",
                            {
                              staticClass: "ml-2",
                              attrs: { href: "#userRoles" },
                            },
                            [_vm._v(" User Roles ")]
                          ),
                          _c(
                            "v-tab",
                            {
                              staticClass: "ml-2",
                              attrs: { href: "#userPermissions" },
                            },
                            [_vm._v(" User Permissions ")]
                          ),
                          _c(
                            "v-tabs-items",
                            { attrs: { value: _vm.tab } },
                            [
                              _c(
                                "v-tab-item",
                                { attrs: { value: "userRoles" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pt-2 pl-2 flex flex-row flex-wrap",
                                    },
                                    _vm._l(_vm.userRoles, function (userRole) {
                                      return _c("div", { key: userRole.id }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(userRole.name) +
                                            " (" +
                                            _vm._s(
                                              userRole.permissions.length
                                            ) +
                                            ") "
                                        ),
                                        _c("br"),
                                      ])
                                    }),
                                    0
                                  ),
                                ]
                              ),
                              _c(
                                "v-tab-item",
                                { attrs: { value: "userPermissions" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pt-2 pl-2 flex flex-row flex-wrap",
                                    },
                                    [
                                      _c("v-data-table", {
                                        staticClass: "elevation-1",
                                        attrs: {
                                          headers: _vm.headers(),
                                          items: _vm.userPermissions,
                                          dense: "",
                                          "items-per-page": 10,
                                          "sort-by": "permission",
                                          "no-data-text": "No Data Available",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "item",
                                            fn: function ({ item }) {
                                              return [
                                                _c("tr", [
                                                  _c("td", [
                                                    _vm._v(_vm._s(item)),
                                                  ]),
                                                  _c(
                                                    "td",
                                                    {
                                                      style: {
                                                        whiteSpace: "pre-line",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.permissionRoles(
                                                            item
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c("app-secondary-button", { on: { click: _vm.close } }, [
                    _vm._v(" Close "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }