var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm.showTooltip
        ? _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: { icon: "", disabled: _vm.disabled },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("click")
                                  },
                                },
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _c("v-icon", { attrs: { color: _vm.color } }, [
                              _vm._v(_vm._s(_vm.icon)),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                2136569384
              ),
            },
            [_c("span", [_vm._v(_vm._s(_vm.tooltip))])]
          )
        : _vm._e(),
      !_vm.showTooltip
        ? _c(
            "v-btn",
            {
              attrs: { icon: "", disabled: _vm.disabled },
              on: {
                click: function ($event) {
                  return _vm.$emit("click")
                },
              },
            },
            [
              _c("v-icon", { attrs: { color: _vm.color } }, [
                _vm._v(_vm._s(_vm.icon)),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }