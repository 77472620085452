var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-text-field", {
    attrs: {
      label: _vm.fullLabel,
      rules: _vm.rules,
      readonly: _vm.readonly,
      disabled: _vm.disabled,
      required: _vm.required,
      type: _vm.type,
      counter: _vm.counter,
      autofocus: _vm.autofocus,
      filled: _vm.filled,
      autocomplete: _vm.autocomplete,
      dense: _vm.dense,
      prefix: _vm.prefix,
      suffix: _vm.suffix,
      "hide-spin-buttons": "",
    },
    on: {
      change: function ($event) {
        return _vm.$emit("change")
      },
      keyup: function ($event) {
        if (
          !$event.type.indexOf("key") &&
          _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
        )
          return null
        return _vm.$emit("enter")
      },
    },
    model: {
      value: _vm.model,
      callback: function ($$v) {
        _vm.model = $$v
      },
      expression: "model",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }