var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      _c("v-combobox", {
        staticClass: "pt-6",
        attrs: {
          "menu-props": { maxHeight: "700" },
          label: "Quick Search",
          "item-text": "display",
          items: _vm.searchItems,
          filter: _vm.searchFilter,
          dense: "",
          outlined: "",
          clearable: "",
        },
        on: {
          "update:search-input": _vm.search,
          change: _vm.searchItemSelected,
        },
        scopedSlots: _vm._u([
          {
            key: "prepend-item",
            fn: function () {
              return [
                _c("v-list-item", [
                  _vm._v(
                    " " + _vm._s(_vm.searchItems.length) + " search hits "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "item",
            fn: function ({ item }) {
              return [
                _c(
                  "div",
                  [
                    _c(
                      "v-list-item",
                      { attrs: { "two-line": "" } },
                      [
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", [
                              _vm._v(_vm._s(item.display)),
                            ]),
                            _c("v-list-item-subtitle", {
                              domProps: { innerHTML: _vm._s(item.marked_text) },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }