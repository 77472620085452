import Vue from "vue"

export const getBreadcrumbs = () => {
  var breadcrumbs = Vue.$cookies.get("breadcrumbs")
  if (breadcrumbs != null && breadcrumbs != undefined) {
    return breadcrumbs
  } else {
    return []
  }
}

export const addToBreadcrumbs = (json) => {
  var breadcrumbs = Vue.$cookies.get("breadcrumbs")
  if (breadcrumbs != null && breadcrumbs != undefined){
    if (breadcrumbs.length >= 5 && !breadcrumbs.some(x => x.title === json.title)){
      breadcrumbs.shift()
    }
    if (!breadcrumbs.some(x => x.title === json.title)){
      breadcrumbs.push(json)
      var breadcrumbsStr = JSON.stringify(breadcrumbs)
      Vue.$cookies.set("breadcrumbs", breadcrumbsStr)
    } else {
      var index = breadcrumbs.findIndex(x => x.title === json.title)
      breadcrumbs.splice(index, 1)
      breadcrumbs.push(json)
      var breadcrumbsStrSpliced = JSON.stringify(breadcrumbs)
      Vue.$cookies.set("breadcrumbs", breadcrumbsStrSpliced)
    }
  } else {
    var result = [json]
    Vue.$cookies.set("breadcrumbs", JSON.stringify(result))
  }
}

export const cleanCookies = () => {
  Vue.$cookies.remove("breadcrumbs")
}

