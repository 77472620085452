import Vue from "vue"
import VueRouter from "vue-router"
import LoginView from "../views/LoginView.vue"
import { getCurrentUser } from "../main.js"
import { addToBreadcrumbs, cleanCookies } from "@/store/breadcrumbsStorage.js"
import eventBus from "@/framework/eventBus"

Vue.use(VueRouter)

const routes = [
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/users",
    name: "users",
    component: () => import("../views/UsersView.vue"),
  },
  {
    path: "/users/:uid",
    name: "user-details",
    props: true,
    component: () => import("../views/UserDetailsView.vue"),
  },
  {
    path: "/sign_out",
    name: "signout",
    component: () => import("../views/SignOutView.vue"),
  },
  {
    path: "/security_roles",
    name: "security-roles",
    component: () => import("../views/SecurityRolesView.vue"),
  },
  {
    path: "/word_templates",
    name: "word-templates",
    component: () => import("../views/WordTemplatesView.vue"),
  },
  {
    path: "/contacts",
    name: "contacts",
    component: () => import("../views/ContactsView.vue"),
  },
  {
    path: "/type_of_laws",
    name: "type-of-laws",
    component: () => import("../views/TypeOfLawView.vue"),
  },
  {
    path: "/contacts/:contactKey",
    name: "contact-details",
    props: true,
    component: () => import("../views/ContactDetailsView.vue"),
  },
  {
    path: "/company_settings",
    name: "company-settings",
    props: true,
    component: () => import("../views/CompanySettingsView.vue"),
  },
  {
    path: "/quickbooks_auth",
    name: "quickbooks-auth",
    props: true,
    component: () => import("../views/QuickbooksAuthorizationView.vue"),
  },
  {
    path: "/client_accounts",
    name: "client-account",
    component: () => import("../views/ClientAccountsView.vue"),
  },
  {
    path: "/client_accounts/:clientAccountKey",
    name: "client-account-details",
    props: true,
    component: () => import("../views/ClientAccountDetailsView.vue"),
  },
  {
    path: "/branches",
    name: "branches",
    props: true,
    component: () => import("../views/BranchView.vue"),
  },
  {
    path: "/matters",
    name: "matters",
    component: () => import("../views/MattersView.vue"),
  },
  {
    path: "/matters/:matterKey",
    name: "matter-details",
    props: true,
    component: () => import("../views/MatterDetailsView.vue"),
  },
  {
    path: "/matters/:matterKey/:selectTab",
    name: "matter-details-select-tab",
    props: true,
    component: () => import("../views/MatterDetailsView.vue"),
  },
  {
    path: "/time_entries",
    name: "time-entries",
    component: () => import("../views/TimeEntriesView.vue"),
  },
  {
    path: "/time_entries/:timeEntryKey",
    name: "time-entry-details",
    props: true,
    component: () => import("../views/TimeEntryDetailsView.vue"),
  },
  {
    path: "/flat_fees/:flatFeeKey",
    name: "flat-fee-details",
    props: true,
    component: () => import("../views/FlatFeeDetailsView.vue"),
  },
  {
    path: "/conflict_search_records",
    name: "conflict-search-records",
    component: () => import("../views/ConflictSearchRecordsView.vue"),
  },
  {
    path: "/conflict_search_records/conflict_search",
    props: true,
    name: "conflict-search-result",
    component: () => import("../views/ConflictSearchView.vue"),
  },
  {
    path: "/type_of_disbursements",
    name: "type-of-disbursements",
    component: () => import("../views/TypeOfDisbursementsView.vue"),
  },
  {
    path: "/disbursements",
    name: "disbursements",
    component: () => import("../views/DisbursementsView.vue"),
  },
  {
    path: "/client_account_known_conflict_record_details/:conflictRecordKey",
    name: "client-account-known-conflict-record-details",
    props: true,
    component: () => import("../views/ClientAccountConflictRecordDetailsView.vue"),
  },
  {
    path: "/quickbooks_sync_logs",
    name: "quickbooks-sync-logs",
    component: () => import("../views/QuickbooksSyncLogsView.vue"),
  },
  {
    path: "/transaction_audit_logs",
    name: "transaction-audit-logs",
    component: () => import("../views/TransactionAuditLogsView.vue"),
  },
  {
    path: "/bills",
    name: "bills",
    component: () => import("../views/BillsView.vue"),
  },
  {
    path: "/bill_details/:billKey/:matterKey",
    name: "bill-details",
    props: true,
    component: () => import("../views/BillDetailsView.vue"),
  },
  {
    path: "/billing_tax_settings",
    name: "billing-tax-settings",
    component: () => import("../views/BillingTaxSettingsView.vue"),
  },
  {
    path: "/trust_receipts",
    name: "trust-receipts",
    component: () => import("../views/TrustRecordsView.vue"),
  },
  {
    path: "/trust_receipts/:trustReceiptKey",
    name: "trust-receipt-details",
    props: true,
    component: () => import("../views/TrustRecordDetailsView.vue"),
  },
  {
    path: "/trust_transfer/:trustTransferKey",
    name: "trust-transfer-details",
    props: true,
    component: () => import("../views/TrustTransferDetailsView.vue"),
  },
  {
    path: "/trust_release_requests",
    name: "release-requests",
    component: () => import("../views/ReleaseRequestsView.vue"),
  },
  {
    path: "/trust_release_requests/:releaseRequestKey",
    name: "release-request-details",
    props: true,
    component: () => import("../views/ReleaseRequestDetailsView.vue"),
  },
  {
    path: "/trust_bill_payment",
    name: "trust-bill-payment",
    component: () => import("../views/TrustBillPaymentView.vue"),
  },
  {
    path: "/trust_bill_payment/:requestKey",
    name: "trust-bill-payment-details",
    props: true,
    component: () => import("../views/TrustBillPaymentDetails.vue"),
  },
  {
    path: "/trust_cheques",
    name: "trust-cheques",
    component: () => import("../views/TrustChequesView.vue"),
  },
  {
    path: "/trust_cheques/:trustChequeKey",
    name: "trust-cheque-details",
    props: true,
    component: () => import("../views/TrustChequeDetailsView.vue"),
  },
  {
    path: "/ar_trust_cheques",
    name: "ar-trust-cheques",
    component: () => import("../views/ArTrustChequesView.vue"),
  },
  {
    path: "/ar_trust_cheques/:chequeKey",
    name: "ar-trust-cheque-details",
    props: true,
    component: () => import("../views/ArTrustChequeDetailsView.vue"),
  },
  {
    path: "/statement_management",
    name: "statement-management",
    component: () => import("../views/StatementManagementView.vue"),
  },
  {
    path: "/general_cheques",
    name: "general-cheques",
    component: () => import("../views/GeneralChequesView.vue"),
  },
  {
    path: "/reports",
    name: "reports",
    component: () => import("../views/ReportView.vue"),
  },
  {
    path: "/billing_report_details",
    name: "billing-report-details",
    component: () => import("../views/BillingReportDetailsView.vue"),
  },
  {
    path: "/system_settings",
    name: "system-settings",
    component: () => import("../views/SystemSettingsView.vue"),
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  let ok = checkAuth(to, next)
  if (ok) {
    next()
  }
  if (to.name === "login" || to.name === "signout") {
    cleanCookies()
  } else {
    var json = {
      title: to.name,
      link: to.fullPath,
    }
    addToBreadcrumbs(json)
  }
  eventBus.$emit("breadUpdated")
})

async function checkAuth(to, next) {
  let isLogin = to.matched.some(record => record.name == "login")
  let ok = isLogin || (await getCurrentUser()) != null

  if (!ok) {
    next({ name: "login", query: { returnUrl: to.fullPath } })
  }

  return ok
}

export default router
