var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("icon-button", {
    attrs: {
      type: _vm.type,
      to: _vm.to,
      tooltip: _vm.tooltip,
      disabled: _vm.disabled,
      icon: _vm.icon,
      color: _vm.color,
    },
    on: {
      click: function ($event) {
        return _vm.$emit("click")
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }