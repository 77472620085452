import Vue from "vue"

export const getCookieData = dataName => {
  var cookieName = "CommonStorage" + dataName

  var result = Vue.$cookies.get(cookieName)
  if (result != "null" && result != "undefined") {
    return result
  }
  return null
}

export const updateCookieData = (dataName, newData) => {
  var cookieName = "CommonStorage" + dataName
  Vue.$cookies.set(cookieName, newData)
}
