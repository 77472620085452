var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list-item",
    { attrs: { to: _vm.link, link: "" } },
    [
      _c("v-list-item-icon", [_c("v-icon", [_vm._v(_vm._s(_vm.icon))])], 1),
      _c(
        "v-list-item-content",
        [_c("v-list-item-title", [_vm._v(_vm._s(_vm.title))])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }