import * as client from "../framework/client"

export const getAll = () => {
  return client.get("/user")
}

export const getAllActiveSimpleUers = () => {
  return client.get("/user/all_active_simple_users")
}

export const getUser = uid => {
  return client.get(`/user/user/${uid}`)
}

export const createUser = createUserDto => {
  return client.post("/user", createUserDto)
}

export const enableUserTwoFactorAuth = enableTwoFactorDto => {
  return client.put("/user/enable_two_factor_auth", enableTwoFactorDto)
}

export const updateUser = updateUserDto => {
  return client.put("/user", updateUserDto)
}

export const usersExcelReport = () => {
  return client.download("/user/excel_report")
}

export const usersWordReport = () => {
  return client.download("/user/word_report")
}

export const usersPdfReport = () => {
  return client.download("/user/pdf_report")
}

export const addLawyerHourlyRate = createCmdDto => {
  return client.put("/user/lawyer/create_hourly_rate", createCmdDto)
}

export const updateLawyerHourlyRate = updateCmdDto => {
  return client.put("/user/lawyer/update_hourly_rate", updateCmdDto)
}

export const getActiveLawyers = () => {
  return client.get("/user/active_lawyers")
}

export const getAllLawyers = () => {
  return client.get("/user/all_lawyers")
}

export const getAvailableLawyersForEditMatter = matterUrlSafeKey => {
  return client.get(`/user/available_lawyers_for_edit_matter/${matterUrlSafeKey}`)
}

export const getBlockedUserForClientAccount = clientAccountUrlSafeKey => {
  return client.get(`/user/blocked_users_for_client_account/${clientAccountUrlSafeKey}`)
}

export const getAvailableUsersForBlockUserOnClientAccount = clientAccountUrlSafeKey => {
  return client.get(`/user/available_users_for_block_user_on_client_account/${clientAccountUrlSafeKey}`)
}

export const addBlockedUserToClientAccount = addCmdDto => {
  return client.put("/user/add_blocked_user_to_client_account", addCmdDto)
}

export const removeBlockedUserFromClientAccount = removeCmdDto => {
  return client.put("/user/remove_blocked_user_from_client_account", removeCmdDto)
}

export const getLawyerSelections = () => {
  return client.get(`/user/lawyer_selections`)
}
