var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ attrs, on }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("click")
                            },
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { color: "white" } }, [
                        _vm._v(" mdi-help-circle-outline "),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "v-card",
            { attrs: { "min-width": "300" } },
            [
              _c(
                "v-list",
                [
                  _c("v-list-item-title", [
                    _c("div", {
                      staticClass: "px-5",
                      domProps: { innerHTML: _vm._s(_vm.messages) },
                    }),
                  ]),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item-title",
                    { staticClass: "px-5" },
                    [
                      _c("small", [_vm._v(" Syntax Document:")]),
                      _c("primary-icon-button", {
                        attrs: {
                          icon: "mdi-file-pdf-box",
                          dense: "",
                          tooltip: "Open Syntax Document",
                        },
                        on: { click: _vm.openSyntaxDocument },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }