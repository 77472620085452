var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-button",
    {
      attrs: {
        color: _vm.color,
        to: _vm.to,
        type: _vm.type,
        disabled: _vm.disabled,
      },
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }