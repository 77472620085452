import Vue from "vue"
import Vuex from "vuex"
import { updateCookieData } from "./commonStorage.js"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentUser: null,
    firebaseUser: null,
  },
  getters: {
    userHasPermission: state => permission => {
      return state.currentUser != null && state.currentUser.user_permissions.includes(permission)
    },
    emailVerified: state => () => {
      return state.firebaseUser != null && state.firebaseUser.emailVerified
    },
    mhfEnabled: state => () => {
      return state.firebaseUser != null && state.firebaseUser.multiFactor.enrolledFactors.length > 0
    },
    currentUser: state => () => {
      return state.currentUser
    },
  },
  mutations: {
    logIn(state, data) {
      state.currentUser = data.mhLawUser
      state.firebaseUser = data.user
      updateCookieData("userName", data.mhLawUser.full_name)
      updateCookieData("userUrlSafeKey", data.mhLawUser.url_safe_key)
      updateCookieData("isLawyer", data.mhLawUser.is_lawyer.toString())
    },
    logOut(state) {
      state.currentUser = null
      state.firebaseUser = null
    },
  },
  actions: {
    updateUser({ commit }, data) {
      if (data && data.mhLawUser && data.user) {
        commit("logIn", data)
      } else {
        commit("logOut")
      }
    },
  },
  modules: {},
})
