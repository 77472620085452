var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-main",
        [
          _c("v-breadcrumbs", {
            attrs: { items: _vm.items },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function ({ item }) {
                  return [
                    _c("v-breadcrumbs-item", { attrs: { href: item.link } }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.title.toUpperCase().split("-").join(" ")
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm.isLoggedIn ? _c("Navigation") : _vm._e(),
          _c("router-view", { key: _vm.$route.fullPath }),
          _c("messaging"),
          _c("loading-overlay"),
          _c("view-user-roles-permissions-dialog", {
            attrs: { routeName: _vm.$route.fullPath },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }