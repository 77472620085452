import Vue from "vue"

export const getFilterArgs = viewName => {
  var filterCookieName = "filterArgs" + viewName

  var filterArgs = Vue.$cookies.get(filterCookieName)
  if (filterArgs != "null" && filterArgs != "undefined") {
    return filterArgs
  }
  return null
}

export const addUpdateFilterArgs = (viewName, filterArgs) => {
  var filterCookieName = "filterArgs" + viewName
  Vue.$cookies.set(filterCookieName, filterArgs)
}

export const getLocalStorage = viewName => {
  return JSON.parse(localStorage.getItem(viewName))
}

export const setLocalStorage = (viewName, data) => {
  localStorage.setItem(viewName, JSON.stringify(data))
}
