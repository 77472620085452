var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "fill-height", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "8", md: "8" } },
            [
              _c(
                "v-card",
                { staticClass: "elevation-12 rounded-lg" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "primary accent-3 rounded-lg rounded-r-0",
                          attrs: { cols: "12", md: "4" },
                        },
                        [
                          _c(
                            "v-card-text",
                            {
                              staticClass:
                                "justify-center mt-12 white--text text-h5",
                            },
                            [_vm._v("Welcome to FidataPro")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "8" } },
                        [
                          _c("v-card-title", [_vm._v("Sign In")]),
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              attrs: { "lazy-validation": "" },
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.login.apply(null, arguments)
                                },
                              },
                              model: {
                                value: _vm.valid,
                                callback: function ($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid",
                              },
                            },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c("app-text-box", {
                                    attrs: {
                                      autocomplete: "username",
                                      label: "Email",
                                      required: "",
                                      rules: _vm.emailRules,
                                      filled: "",
                                    },
                                    on: { enter: _vm.login },
                                    model: {
                                      value: _vm.email,
                                      callback: function ($$v) {
                                        _vm.email = $$v
                                      },
                                      expression: "email",
                                    },
                                  }),
                                  _c("app-text-box", {
                                    attrs: {
                                      autocomplete: "current-password",
                                      label: "Password",
                                      type: "password",
                                      required: "",
                                      rules: _vm.passwordRules,
                                      filled: "",
                                    },
                                    on: { enter: _vm.login },
                                    model: {
                                      value: _vm.password,
                                      callback: function ($$v) {
                                        _vm.password = $$v
                                      },
                                      expression: "password",
                                    },
                                  }),
                                  _vm.error
                                    ? _c(
                                        "v-alert",
                                        {
                                          attrs: {
                                            type: "error",
                                            dense: "",
                                            outlined: "",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.error))]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c(
                                    "app-primary-button",
                                    {
                                      attrs: { disabled: _vm.loginInProgress },
                                      on: { click: _vm.login },
                                    },
                                    [_vm._v(" Login ")]
                                  ),
                                  _c(
                                    "app-secondary-button",
                                    {
                                      attrs: {
                                        small: true,
                                        disabled: _vm.forgotPasswordDisabled,
                                      },
                                      on: { click: _vm.forgotPassword },
                                    },
                                    [_vm._v("Forgot Password?")]
                                  ),
                                ],
                                1
                              ),
                              !_vm.verificationId
                                ? _c("div", [
                                    _c("div", {
                                      attrs: { id: "recaptcha-container-id" },
                                    }),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-form",
                            {
                              ref: "form2",
                              attrs: { "lazy-validation": "" },
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.sendMfaCode.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _vm.verificationId
                                ? _c(
                                    "v-container",
                                    [
                                      _c(
                                        "v-row",
                                        { staticClass: "d-flex align-center" },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("app-text-box", {
                                                attrs: {
                                                  label: "Enter 2FA Code",
                                                  rules: _vm.TwoFACodeRules,
                                                },
                                                on: {
                                                  keydown: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    )
                                                      return null
                                                    return _vm.sendMfaCode.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: _vm.mfaCode,
                                                  callback: function ($$v) {
                                                    _vm.mfaCode = $$v
                                                  },
                                                  expression: "mfaCode",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            [
                                              _c(
                                                "app-primary-button",
                                                {
                                                  on: {
                                                    click: _vm.sendMfaCode,
                                                  },
                                                },
                                                [_vm._v("Send")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("reset-password-dialog", { attrs: { routeName: _vm.routeName } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }